import { render, staticRenderFns } from "./InvoiceModalReturn.vue?vue&type=template&id=4806855d&"
import script from "./InvoiceModalReturn.vue?vue&type=script&lang=ts&"
export * from "./InvoiceModalReturn.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4806855d')) {
      api.createRecord('4806855d', component.options)
    } else {
      api.reload('4806855d', component.options)
    }
    module.hot.accept("./InvoiceModalReturn.vue?vue&type=template&id=4806855d&", function () {
      api.rerender('4806855d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/account-receivables/invoice-detail/components/InvoiceModalReturn.vue"
export default component.exports